<div class="video-grid">
    <div class="video-item">
        <img #img
            (error)="this.img.src = '/images/g2g-logo.png'; this.img.style.padding = '20px'; this.img.style.objectFit='contain'"
            (click)="gotoVideo(post?.id)" class="video-thumbnail" [src]="post?.thumbnail">
        <div class="video-details">
            <h3 (click)="gotoVideo(post?.id)" class="video-title line-clamp">{{post?.title}}</h3>
            <p class="video-meta line-clamp">
                <span (click)="gotoUserProfile(post?.user?.id)" class="player-name">{{post?.user?.name}}</span> • 15 • England
            </p>
            <p class="video-stats">{{post?.createdOn | ago}}</p>
            @if(post?.likes) {
            <p class="video-stats">
                {{post?.likes}} Likes
            </p>
            }
        </div>
    </div>
</div>